<div class='con-info-group first-form'>
  <div>
    <h5>{{ 'BtnText.ContactInfo' | translate }}</h5>
    <div class='error-text' *ngIf='!contactFormIsValid' #contactInfoRequiredEl>
      {{ 'Message.ContactInfoNameRequired' | translate }}
    </div>
  </div>
  <button class='btn btn-oclm' (click)='showContactInfoModal()'>
    {{ 'BtnText.ViewDetails' | translate }}
  </button>
  <button *ngIf='WorkIdVal && isEditMode' class='btn btn-oclm' (click)='toggleEdit()'>
    Edit
  </button>
  <button *ngIf='showScheduleDate' class='btn btn-oclm' (click)='updateWorkOrderStatus()'>
    Start the Work
  </button>
</div>

<form [formGroup]='workOrderForm'>
  <div class='row'>
    <div class='col-md-4 col-xs-6 col-sm-6'>
      <label>
        {{ 'CommonLabel.WorkOrderID' | translate }} -
        {{ 'CommonLabel.CreatedOn' | translate }}
      </label>
      <div class='wrp'>
        <input readonly [value]='workOrderIdTemp' type='text'
               placeholder=" {{ 'CommonLabel.WorkOrderID' | translate }} - {{'CommonLabel.CreatedOn' | translate}}"
               class='form-control w-100' />
        <img src='../../../../../assets/image/reports-icon-t.svg' class='user-icon' style='top: 9px' alt='icon' />
      </div>
    </div>

    <div class='col-md-4 offset-md-4 col-xs-6 col-sm-6'>
      <div class='form-group'>
        <label class='oclm-color'>{{'CommonLabel.WorkOrderStatus' | translate}}</label>
        <div class='wrp'>
          <img src='../../../../../../assets/image/reports-icon-t.svg' class='user-icon' style='top: 9px' alt='icon' />
          <select [attr.disabled]="isDisabledStatus ? '' : null" class='form-control'
                  (change)='workOrderStatusChange($event)' formControlName='WorkStatusText' [ngClass]="{
              'is-invalid': submitted && f.WorkStatusText.errors
            }">
            <option [value]='null'>
              -{{ 'BtnText.SelectStatus' | translate }}-
            </option>
            <option *ngFor='let value of statusList' [value]='value.Text'>
              {{ value.DisplayText }}
            </option>
          </select>
          <div *ngIf='submitted && f.WorkStatusText.errors' class='invalid-feedback'>
            <div *ngIf='f.WorkStatusText.errors.required'>
              Please select status
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class='row'>

    <div class='col-12 col-sm-7'>
      <ng-container *ngIf="currentUserRole === 'Admin' || currentUserRole === 'SuperVisor';
       else notForAdminTemp">
        <div class='row'>

          <div class='col-xs-12' [ngClass]="currentUserRole === 'Admin' ? 'col-md-6 col-sm-6 '
                : 'col-md-12 col-sm-12 '">
            <div class='form-group'>
              <label>
                {{ 'CommonLabel.JobName' | translate }} /
                {{ 'CommonLabel.CustomerName' | translate }}
              </label>
              <div class='wrp'>
                <ng-container *ngIf='isEditMode; else adminCustomerTmpl'>
                  <input readonly [value]='readCustomerId' type='text'
                         placeholder=" {{ 'CommonLabel.JobName' | translate }}"
                         class='form-control w-100' />
                  <img src='../../../../assets/image/work-request-icon.png'
                       class='user-icon' alt='icon' />
                </ng-container>
                <!--Select component-->
                <ng-template #adminCustomerTmpl>
                  <img src='../../../../assets/image/work-request-icon.png'
                       class='user-icon'
                       alt='icon' />
                  <select class='form-control'
                          formControlName='CustomerId'
                          (change)='getSelectedJobs($event)'
                          [ngClass]="{'is-invalid': submitted && f.CustomerId.errors}">
                    <option [value]='null'>
                      -{{ 'CommonLabel.Select' | translate }}-
                    </option>
                    <option *ngFor='let value of jobListDetails' [value]='value.Id'>
                      {{ value.Name }}
                    </option>
                  </select>
                </ng-template>
                <!--End select component-->
                <!-- job name search -->

                <div *ngIf='submitted && f.CustomerId.errors' class='invalid-feedback'>
                  <div *ngIf='f.CustomerId.errors.required'>
                    {{ 'Message.JobCustomerNameRequired' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class='col-md-6 col-sm-6 col-xs-12' *ngIf="currentUserRole === 'Admin'">
            <div class='form-group'>
              <label>
                {{ 'CommonLabel.Select' | translate }}
              </label>
              <div class='wrp'>

                <!-- supervisor search -->

                <ng-container *ngIf='isEditMode; else adminSupervisor'>
                  <select class='form-control' formControlName='SuperVisorId' disabled>
                    <option *ngFor='let value of supervisorList' [value]='value.ID'>
                      {{ value.Text }}
                    </option>
                  </select>
                  <img src='../../../../assets/image/work-request-icon.png' class='user-icon' alt='icon' />
                </ng-container>

                <ng-template #adminSupervisor>
                  <mat-form-field floatLabel='never'>
                    <mat-label class='label'>-{{ 'CommonLabel.Select' | translate }}-</mat-label>
                    <mat-select class='form-control' formControlName='SuperVisorId' multiple
                                [ngClass]="{'is-invalid':submitted && f.SuperVisorId.errors }"
                                id='supervisorSelect'>

                      <mat-option disabled *ngFor='let value of supervisorList' [value]='value.ID'>
                        {{value.Text}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf='f.SuperVisorId.errors'>
                      {{f.SuperVisorId.errors}}
                    </mat-error>
                  </mat-form-field>
                </ng-template>

                <div *ngIf='submitted && f.SuperVisorId.errors' class='invalid-feedback'>
                  <div *ngIf='f.SuperVisorId.errors.required'>
                    {{ 'Message.Supervisor' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col-md-6 col-sm-6 col-xs-12' *ngIf="currentUserRole === 'Admin' && isForClient">
            <div class='form-group'>
              <label>
                {{ 'CommonLabel.Client' | translate }}
              </label>
              <div class='wrp'>
                <!-- clients autocomplete -->
                <ng-container *ngIf='isEditMode; else adminClient'>
                  <select class='form-control' formControlName='CreatedBy' disabled>
                    <option *ngFor='let value of clientList' [value]='value.UserId'>
                      {{ value.FirstName }} {{ value.LastName }}
                    </option>
                  </select>
                  <img src='../../../../assets/image/work-request-icon.png' class='user-icon' alt='icon' />
                </ng-container>

                <ng-template #adminClient>
                  <select class='form-control' formControlName='CreatedBy'
                          (change)='setClientValue($event)'
                          [ngClass]="{'is-invalid': submitted && f.CreatedBy.errors}">
                    <option [value]='null'>
                      -{{ 'CommonLabel.Select' | translate }}-
                    </option>
                    <option *ngFor='let value of clientList' [value]='value.UserId'>
                      {{ value.FirstName }} {{ value.LastName }}
                    </option>
                  </select>
                </ng-template>

                <div *ngIf='submitted && f.SuperVisorId.errors' class='invalid-feedback'>
                  <div *ngIf='f.SuperVisorId.errors.required'>
                    {{ 'Message.Supervisor' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #notForAdminTemp>
        <div class='form-group'>
          <label>
            {{ 'CommonLabel.JobName' | translate }} /
            {{ 'CommonLabel.CustomerName' | translate }}
          </label>
          <div class='wrp'>
            <ng-container *ngIf='isEditMode; else customerTmpl'>
              <input readonly [value]='readCustomerId' type='text'
                     placeholder=" {{ 'CommonLabel.JobName' | translate }}" class='form-control w-100' />
            </ng-container>
            <ng-template #customerTmpl>
              <select class='form-control' formControlName='CustomerId'
                      (change)='getSelectedJobs($event)'
                      [ngClass]="{'is-invalid': submitted && f.CustomerId.errors}">
                <option [value]='null'>
                  -{{ 'CommonLabel.Select' | translate }}-
                </option>
                <option *ngFor='let value of jobListDetails' [value]='value.Id'>
                  {{ value.Name }}
                </option>
              </select>
            </ng-template>
            <img src='../../../../assets/image/work-request-icon.png' class='user-icon' alt='icon' />
            <div *ngIf='submitted && f.CustomerId.errors' class='invalid-feedback'>
              <div *ngIf='f.CustomerId.errors.required'>
                {{ 'Message.JobCustomerNameRequired' | translate }}
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <div class='form-group'>
        <label>
          {{ 'CommonLabel.addressOfTheIssue' | translate }}
        </label>
        <div class='wrp'>
          <ng-container *ngIf='isEditMode || currentRoute === true'>
            <input readonly [value]='readAddress' type='text'
                   formControlName='Address'
                   placeholder="{{ 'CommonLabel.LocationOrDragMarker' | translate }}"
                   class='form-control w-100' />
          </ng-container>
          <div [style.display]="isEditMode === true || currentRoute === true ? 'none' : 'block'">
            <input type='text'
                   placeholder="{{ 'CommonLabel.LocationOrDragMarker' | translate }}"
                   formControlName='Address'
                   (keydown.enter)='setLocationName($event)'
                   (focusout)='setLocationName($event)'
                   autocorrect='off'
                   autocapitalize='off'
                   spellcheck='off'
                   #search [ngClass]="{'is-invalid': submitted && f.Address.errors}"
                   class='form-control' />
          </div>

          <img src='../../../../assets/image/region-icon.png' class='user-icon' alt='icon' />
          <div style='display: flex'>
            <button [disabled]='isLoadingCurrentLocation || isEditMode' class='
                btn btn-oclm btn-sm
                mt-1
                d-sm-block d-xs-block
                tablet-prod
                d-md-none d-lg-none d-xl-none
              ' (click)='handleCurrentLocation()'>
              {{
              isLoadingCurrentLocation
                ? 'Use Current Location'
                : 'Use Current Location'
              }}
            </button>

            <a style='text-decoration: underline; cursor: pointer'
               href='https://www.google.com/maps/place/{{ readAddress }}' class='
                btn btn-link
                d-sm-block d-xs-block
                tablet-prod
                d-md-none d-lg-none d-xl-none
              '>View Direction</a>
          </div>

          <div *ngIf='submitted && f.Address.errors' class='invalid-feedback'>
            <div *ngIf='f.Address.errors.required'>
              {{ 'Message.LocationRequired' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class='form-group'>
        <label>{{ 'Table.WorkCategory' | translate }}</label>
        <div class='wrp'>
          <ng-container *ngIf='isEditMode; else workCategoryTmpl'>
            <input readonly [value]='readWorkCategory' type='text'
                   placeholder=" {{ 'Table.WorkCategory' | translate }} " class='form-control w-100' />
          </ng-container>
          <ng-template #workCategoryTmpl>
            <select class='form-control'
                    id='workcatergory'
                    formControlName='WorkCategory'
                    (change)='onChangeOfWorkCategory($event.target.value)'
                    [ngClass]="{'is-invalid': submitted && f.WorkCategory.errors}">
              <option [ngValue]='null'>
                -{{ 'CommonLabel.Select' | translate }}-
              </option>
              <option *ngFor='let WorkCategory of workCategoryList' [ngValue]='WorkCategory.Text'>
                {{ WorkCategory.Text }}
              </option>
            </select>
          </ng-template>
          <img src='../../../../assets/image/job-icon.svg' class='user-icon' alt='icon' />
          <div *ngIf='submitted && f.WorkCategory.errors' class='invalid-feedback'>
            <div *ngIf='f.WorkCategory.errors.required'>
              {{ 'Message.WorkCategoryRequired' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class='form-group' *ngIf="createdBy">
        <label>{{ 'Table.CreatedBy' | translate }}</label>
        <div class='wrp'>
          <ng-container>
            <input readonly [value]='createdBy' type='text'
                   placeholder=" {{ 'Table.CreatedBy' | translate }} " class='form-control w-100' />
          </ng-container>
          <img src='../../../../assets/image/job-icon.svg' class='user-icon' alt='icon' />
        </div>
      </div>

      <div class='form-group'>
        <label>
          {{ 'CommonLabel.WorkRequest' | translate }}
        </label>
        <div class='wrp wrp-description'>
          <ng-container *ngIf='isEditMode; else workRequestTmpl'>
            <div class='description'>
              <span>
                {{ readWorkRequestID }}
              </span>
            </div>
          </ng-container>
          <ng-template #workRequestTmpl>
            <textarea type='text' cols='4' rows='3' (keyup)='autoGrowTextZone($event)' formControlName='WorkRequestID'
                      [ngClass]="{
                'is-invalid': submitted && f.WorkRequestID.errors
              }" placeholder="{{ 'CommonLabel.WorkRequest' | translate }}" class='form-control'
                      style='word-wrap: break-word'></textarea>
          </ng-template>

          <img src='../../../../../assets/image/comment-icon.png' class='user-icon' alt='icon' />
          <div *ngIf='submitted && f.WorkRequestID.errors' class='invalid-feedback'>
            <div *ngIf='f.WorkRequestID.errors.required'>
              {{ 'Message.DescriptionWorkRequired' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class='form-group' *ngIf='AdditionalComment'>
        <label>
          {{ 'CommonLabel.additionalComment' | translate }}
        </label>
        <div class='wrp wrp-description'>
          <div class='description'>
            <span>
              {{ AdditionalComment }}
            </span>
          </div>
          <img src='../../../../../assets/image/comment-icon.png' class='user-icon' alt='icon' />
          <div *ngIf='submitted && f.WorkRequestID.errors' class='invalid-feedback'>
            <div *ngIf='f.WorkRequestID.errors.required'>
              {{ 'Message.DescriptionWorkRequired' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="
          !showScheduleDate &&
          workOrderForm['controls'].CompletedOn.value != null
        " class='form-group d-md-none d-lg-none d-xl-none d-xs-block d-sm-block'>
        <div class='form-group'>
          <label>
            {{ 'CommonLabel.CompleteBy' | translate }}
          </label>
          <div class='wrp'>
            <input type='text' placeholder="{{ 'CommonLabel.CompleteBy' | translate }}" bsDatepicker
                   [bsConfig]='bsConfig' [minDate]='minDate' formControlName='CompletedOn' class='form-control' />
            <img src='../../../../assets/image/date-icon.svg' class='user-icon' alt='icon' />
          </div>
        </div>
      </div>

      <div *ngIf="
          !showScheduleDate &&
          workOrderForm['controls'].CompletedOn.value == null"
           class='d-md-none d-lg-none d-xl-none d-xs-block d-sm-block'>
        <div class='form-group'>
          <label> {{ 'CommonLabel.CompleteBy' | translate }} </label>
          <div class='wrp'>
            <input type='text' placeholder="{{ 'CommonLabel.CompleteBy' | translate }}" bsDatepicker
                   [bsConfig]='bsConfig' [minDate]='minDate' formControlName='CompletedBy' class='form-control' />
            <img src='../../../../assets/image/date-icon.svg' class='user-icon' alt='icon' />
          </div>
        </div>
      </div>

      <ng-container *ngIf='showScheduleDate'>
        <div class='form-group d-md-none d-lg-none d-xl-none d-xs-block d-sm-block'>
          <label> {{ 'CommonLabel.CompleteBy' | translate }} </label>
          <div class='wrp'>
            <input type='text' placeholder="{{ 'CommonLabel.CompleteBy' | translate }}" bsDatepicker
                   [bsConfig]='bsConfig' [minDate]='minDate' formControlName='CompletedBy' class='form-control' />
            <img src='../../../../assets/image/date-icon.svg' class='user-icon' alt='icon' />
          </div>
        </div>
      </ng-container>

      <div *ngIf='showScheduleDate'>
        <div class='form-group d-md-none d-lg-none d-xl-none d-xs-block d-sm-block'>
          <label>
            {{ 'CommonLabel.ScheduleBy' | translate }}
          </label>
          <div class='wrp'>
            <input type='text' formControlName='StartDate' placeholder="{{ 'CommonLabel.ScheduleBy' | translate }}"
                   bsDatepicker [bsConfig]='bsConfig' [minDate]='minDate' class='form-control' />
            <img src='../../../../assets/image/date-icon.svg' class='user-icon' alt='icon' />
          </div>
        </div>
      </div>

      <div *ngIf='!woExist'>
        <div class='form-group'>
          <label>{{ 'CommonLabel.Notes' | translate }}</label>
          <div class='wrp'>
            <textarea placeholder="{{ 'CommonLabel.Notes' | translate }}" rows='2' (keyup)='autoGrowTextZone($event)'
                      formControlName='Notes' class='form-control'></textarea>

            <img src='../../../../../assets/image/comment-icon.png' class='user-icon' alt='icon' />
          </div>
        </div>
      </div>

      <div *ngIf='WorkOrderNotesDetail && WorkOrderNotesDetail.length > 0'>
        <div class='form-group'>
          <label>
            {{ 'CommonLabel.ViewNotes' | translate }}
          </label>
          <div class='view-note-box'>
            <!-- 1st box -->
            <ng-container *ngFor='let item of WorkOrderNotesDetail'>
              <div class='view-note' *ngIf='item && item.NotesText && item.NotesText.length > 0'>
                <div class='notify-msg'>
                  {{ item.CreatedBy | firstWord }}
                </div>
                <div class='notify-text'>
                  <span class='notify-date'>
                    {{ item.CreatedBy }},
                    {{ item.CreatedDate | date: 'd MMMM, y, h:mm a' }}
                  </span>
                  <span style='color: #adadad !important'>{{
                    item.NotesText
                    }}</span>
                </div>
              </div>
              <!-- 2st box -->
            </ng-container>
          </div>
        </div>
      </div>

      <div *ngIf='woExist'>
        <div class='form-group'>
          <label>{{ 'CommonLabel.Notes' | translate }}</label>
          <div class='wrp notes'>
            <textarea placeholder="{{ 'CommonLabel.Notes' | translate }}" rows='2' (keyup)='autoGrowTextZone($event)'
                      formControlName='Notes' class='form-control' [ngClass]="{
                'is-invalid': submitted && f.Notes.errors
              }"></textarea>

            <div *ngIf='submitted && f.Notes.errors' class='invalid-feedback'>
              <div *ngIf='f.Notes.errors.required'>
                Canned Response is required
              </div>
            </div>

            <img src='../../../../../assets/image/comment-icon.png' class='user-icon' alt='icon' />
            <div class='d-flex'>
              <button *ngIf="
                  currentUserRole === 'CommunityManager' &&
                  currentStatus === 'Completed'
                " class='btn btn-oclm btn-sm mt-2 mr-2' (click)="createWorkOrder('reopen')">
                Reopen
              </button>
              <button class='btn btn-oclm btn-sm mt-2' (click)="createWorkOrder('notes')">
                Save Note
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--      <div *ngIf="currentUserRole === 'FieldAgent'">-->
      <!--        <div class="form-group">-->
      <!--          <label class="custom-control custom-checkbox">-->
      <!--            <input type="checkbox" formControlName="notifyCheck" class="custom-control-input"/>-->
      <!--            <span class="custom-control-label">{{-->
      <!--              'BtnText.NotifyUser' | translate-->
      <!--              }}</span>-->
      <!--          </label>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- TODO  Above block is hidden because the OCLM team doesn’t know how to use it.
                    For now if checkbox is checked then the assigned to WO
                    user receives email notification about WO update -->

      <div class='form-group'>
        <label>
          {{ 'CommonLabel.CannedResponse' | translate }}
        </label>
        <div class='wrp'>
          <select [attr.disabled]="currentUserRole === 'CommunityManager'  ? '' : null"
                  class='form-control'
                  id='cannedCategory'
                  formControlName='CannedResponseId'
                  (change)='getCannedResponseText($event.target.value)'
                  [ngClass]="{'is-invalid': submitted && f.CannedResponseId.errors}">
            <option [value]='null'>
              -{{ 'CommonLabel.Select' | translate }}-
            </option>
            <option *ngFor='let value of cannedResponseList' [ngValue]='value.ID'>
              {{ value.Text }}
            </option>
          </select>
          <img src='../../../../assets/image/job-icon.svg' class='user-icon' alt='icon' />
          <div *ngIf='submitted && f.CannedResponseId.errors' class='invalid-feedback'>
            <div *ngIf='f.CannedResponseId.errors.required'>
              Canned Response is required
            </div>
          </div>
        </div>
      </div>

      <!--  Additional Labels -->
      <ng-container *ngIf="job?.Labels?.length">
        <div *ngFor="let label of job.Labels; let i = index" class="form-group">
          <label>{{ label.Name }}</label>
          <div class="wrp">
            <select class="form-control"
                    [formControlName]="'Label' + label.LabelId"
                    (change)="onLabelChange($event, label.LabelId)"
                    [attr.disabled]="currentUserRole === 'CommunityManager' ? '' : null">
              <option value="null">
                -{{ 'CommonLabel.Select' | translate }}-
              </option>
              <option *ngFor="let value of label.Values" [ngValue]="value.ValueId">
                {{ value.Value }}
              </option>
            </select>
            <img src="../../../../assets/image/job-icon.svg" class="user-icon" alt="icon" />
            <div *ngIf="submitted && workOrderForm.get('Label' + label.LabelId)?.errors" class="invalid-feedback">
              <div *ngIf="workOrderForm.get('Label' + label.LabelId)?.errors?.required">
                {{ label.Name }} is required.
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class='form-group'>
        <div class='wrp'>
          <ng-container *ngIf='isDisabledCannedResponseText; else CannedResponseTextTmpl'>
            <textarea disabled cols='3' type='text' placeholder='More on canned response'
                      formControlName='CannedResponseText' class='form-control' [ngClass]="{
                'is-invalid': submitted && f.CannedResponseText.errors
              }"></textarea>
          </ng-container>
          <ng-template #CannedResponseTextTmpl>
            <textarea [attr.disabled]="
                currentUserRole === 'CommunityManager' ? '' : null
              " cols='3' type='text' placeholder='More on canned response' formControlName='CannedResponseText'
                      class='form-control' [ngClass]="{
                'is-invalid': submitted && f.CannedResponseText.errors
              }"></textarea>
          </ng-template>

          <img src='../../../../assets/image/job-icon.svg' class='user-icon' alt='icon' />
          <div *ngIf='submitted && f.CannedResponseText.errors' class='invalid-feedback'>
            <div *ngIf='f.CannedResponseText.errors.required'>
              More on Canned Response is required
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngFor='let item of DynamicFieldsList; let i = index'>
        <div class='form-group'>
          <label>{{ item.FieldName }}</label>
          <div class='wrp'>
            <input class='form-control mb-3' [ngModelOptions]='{ standalone: true }' [(ngModel)]='item.Values'
                   placeholder='Values' />
            <img src='../../../../assets/image/job-icon.svg' class='user-icon' alt='icon' />
            <!-- date-icon.svg -->

            <button (click)='onDelete(item.Values)' class='btn btn-oclm btn-sm'>
              &#10008;
            </button>
          </div>
        </div>
      </ng-container>

      <div class='add-field'>
        <span>
          <strong>{{ 'BtnText.AddMoreField' | translate }}</strong>
        </span>
        <span>
          <app-add-more-fields (onAddMoreFields)='onAddMoreFieldsResponse($event)'></app-add-more-fields>
        </span>
      </div>

      <div class='row'>
        <div class='col-md-6 col-xs-6 col-sm-6'>
          <p class='thumb-text'>
            {{ 'CommonLabel.BeforeCreationWorkOrder' | translate }}
          </p>
          <app-preview-file
            [uploadFile]='uploadBeforePhotoFilePath'
            [beforeComments]='uploadBeforeCommentData'
            [WorkIdVal]='WorkIdVal'
            [view]='true'
            [workOrderType]='workOrderType'
            [isDelete]='true'
            [isEdit]='true'
            [BeforeCreationWorkOrder]='workOrderTypes.BeforeCreationWorkOrder'
            (onImageDelete)='onImageDelete($event)'
            (onImageCommentUpdated)='onImageCommentUpdated($event, workOrderTypes.BeforeCreationWorkOrder)'
            (updateBeforeCommentWhileCreatingOrder)='saveBeforeCommentWhileCreatingOrder($event)'
            (updateAfterCommentWhileCreatingOrder)='saveAfterCommentWhileCreatingOrder($event)'
          >
          </app-preview-file>

          <app-multi-file-upload
            (OnFileUploadResponse)='fileUploadResponseBefore($event)'
            [UploadBtnText]="'Upload Picture'">
          </app-multi-file-upload>
        </div>
        <div class='col-md-6 col-xs-6 col-sm-6'>
          <p class='thumb-text'>
            {{ 'CommonLabel.AfterCreationWorkOrder' | translate }}
          </p>
          <app-preview-file
            [uploadFile]='uploadAfterPhotoFilePath'
            [afterComments]='uploadAfterCommentData'
            [WorkIdVal]='WorkIdVal'
            [isDelete]='true'
            [isEdit]='true'
            [view]='true'
            [AfterCreationWorkOrder]='workOrderTypes.AfterCreationWorkOrder'
            [workOrderType]='workOrderType'
            (onImageCommentUpdated)='onImageCommentUpdated($event, workOrderTypes.AfterCreationWorkOrder)'
            (updateAfterCommentWhileCreatingOrder)='saveAfterCommentWhileCreatingOrder($event)'
            (onImageDelete)='onImageDelete($event)'>
          </app-preview-file>
          <div
            [ngClass]="{disabledNoOfCasesDiv:workOrderForm.value.WorkStatusText == 'Pending'}">
            <app-multi-file-upload (OnFileUploadResponse)='fileUploadResponseAfter($event)'
                                   [UploadBtnText]="'Upload Picture'">
            </app-multi-file-upload>
          </div>

        </div>
      </div>

      <!-- Adding MySelf -->

      <div class='row mt-4'>
        <div class='col-md-6'>
          <div class='form-group' *ngIf="currentUserRole === 'FieldAgent'">
            <label>{{ 'BtnText.AssignAgent' | translate }} </label>
            <div class='wrp'>
              <img src='../../../../../assets/image/field-agent-icon-21.svg' class='user-icon' alt='icon' />
              <select class='form-control' formControlName='FieldAgentId' (change)='onFieldAgentDetails($event)'
                      [ngClass]="{
                  'is-invalid': submitted && f.FieldAgentId.errors
                }">
                <option [value]='null'>
                  -{{ 'CommonLabel.Select' | translate }}-
                </option>
                <option *ngFor='let fieldAgent of fieldAgentListDetails' [value]='fieldAgent.Id'>
                  {{ fieldAgent.Name }}
                </option>
              </select>
              <div *ngIf='submitted && f.FieldAgentId.errors' class='invalid-feedback'>
                <div *ngIf='f.FieldAgentId.errors.required'>
                  {{ 'CommonLabel.fieldAgentError' | translate }}
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="currentUserRole === 'SuperVisor'">
            <label> Supervisor / Field Agent</label>
            <mat-form-field floatLabel='never'>
              <mat-label class='label'>-{{ 'CommonLabel.Select' | translate }}-</mat-label>
              <mat-select class='form-control'
                          formControlName='SuperVisorId'
                          multiple
                          [ngClass]="{'is-invalid':submitted && f.SuperVisorId.errors }"
                          id='supervisorSelect'>
                <mat-option *ngFor='let value of supervisorList' [value]='value.ID' (click)='unCheck(value)'>
                  {{value.Text}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf='f.SuperVisorId.errors'>
                {{f.SuperVisorId.errors}}
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </div>

      <!-- end -->

      <div class='mt-5 groupButtons'>
        <div class='form-group'>
          <span *ngIf='showScheduleDate; else elseSpan'>
            <button class='btn btn-oclm-null' (click)="createWorkOrder('saveWithSchedule')">
              {{ btnText }}
            </button>
          </span>
          <ng-template #elseSpan>
            <button type='submit' class='btn btn-oclm-null' data-toggle='modal' (click)='createWorkOrder(btnText)'>
              {{ btnText }}
            </button>
          </ng-template>
        </div>
        <div class='download-cls' *ngIf='existingWorkOrderDetails'>
          <app-download-work-order [WorkIdVal]='WorkIdVal' [workOrderType]='workOrderType'></app-download-work-order>
        </div>
      </div>
    </div>

    <div class='col-12 col-sm-5 mb-3'>
          <span class='font-weight-600 black'>
            Issue Location Map View
          </span>
      <app-google-map [extLat]='latitude' agmHeight='310px'
                      [extLong]='longitude'
                      [zipCode]='city'
                      [markerDraggable]='isMapDisabledMode'
                      (gmapResponse)='gmapResponseUpdate($event)'
                      (gmapForCityName)='gmapResponseForCityName($event)'>
      </app-google-map>

      <div *ngIf="!showScheduleDate && workOrderForm['controls'].CompletedOn.value !== null"
           class='d-none d-sm-block d-md-block d-lg-block d-xl-block'>
        <div class='form-group'>
          <label> {{ 'CommonLabel.CompleteBy' | translate }} </label>
          <div class='wrp'>
            <input type='text'
                   placeholder="{{ 'CommonLabel.CompleteBy' | translate }}"
                   bsDatepicker
                   [bsConfig]='bsConfig'
                   [minDate]='minDate'
                   formControlName='CompletedOn'
                   class='form-control' />
            <img src='../../../../assets/image/date-icon.svg' class='user-icon' alt='icon' />
          </div>
        </div>
      </div>

      <div *ngIf="
          !showScheduleDate &&
          workOrderForm['controls'].CompletedOn.value == null
        " class='d-none d-sm-block d-md-block d-lg-block d-xl-block'>
        <div class='form-group'>
          <label> {{ 'CommonLabel.CompleteBy' | translate }} </label>
          <div class='wrp'>
            <input type='text' placeholder="{{ 'CommonLabel.CompleteBy' | translate }}" bsDatepicker
                   [bsConfig]='bsConfig' [minDate]='minDate' formControlName='CompletedBy' class='form-control' />
            <img src='../../../../assets/image/date-icon.svg' class='user-icon' alt='icon' />
          </div>
        </div>
      </div>

      <div class='form-group d-none d-sm-block d-md-block d-lg-block d-xl-block'>
        <div *ngIf='showScheduleDate'>
          <label>
            {{ 'CommonLabel.ScheduleBy' | translate }}
          </label>
          <div class='wrp'>
            <input type='text' formControlName='StartDate' placeholder="{{ 'CommonLabel.ScheduleBy' | translate }}"
                   bsDatepicker [bsConfig]='bsConfig' [minDate]='minDate' class='form-control' />
            <img src='../../../../assets/image/date-icon.svg' class='user-icon' alt='icon' />
          </div>
        </div>
      </div>
      <div class='form-group d-none d-sm-block d-md-block d-lg-block d-xl-block'>
        <ng-container *ngIf='showScheduleDate'>
          <label> {{ 'CommonLabel.CompleteBy' | translate }} </label>
          <div class='wrp'>
            <input type='text' placeholder="{{ 'CommonLabel.CompleteBy' | translate }}" bsDatepicker
                   [bsConfig]='bsConfig' [minDate]='minDate' formControlName='CompletedBy' class='form-control' />
            <img src='../../../../assets/image/date-icon.svg' class='user-icon' alt='icon' />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</form>

<ng-container *ngFor='let item of uploadAfterPhotoFilePath; let i = index'>
  <!-- The Modal -->
  <div class='modal fade' id='model{{ i }}'>
    <div class='modal-dialog modal-dialog-centered'>
      <div class='modal-content'>
        <button type='button' class='close' data-dismiss='modal'>×</button>
        <!-- Modal body -->
        <div class='modal-body'>
          <img [src]='apiUrl + item' class='img-fluid' alt='icon' />
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngFor='let item of uploadBeforePhotoFilePath; let i = index'>
  <!-- The Modal -->
  <div class='modal fade' id='model0{{ i }}'>
    <div class='modal-dialog modal-dialog-centered'>
      <div class='modal-content'>
        <button type='button' class='close' data-dismiss='modal'>×</button>
        <!-- Modal body -->
        <div class='modal-body'>
          <img [src]='apiUrl + item' class='img-fluid' alt='icon' />
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #contactInfo>
  <div class='modal-body'>
    <div class='row' [formGroup]='ContactForm'>
      <div class='col-md-12 form-group'>
        <label>{{ 'CommonLabel.FirstName' | translate }}</label>
        <div class='wrp'>
          <input type='text' formControlName='ContactFirstName' placeholder="{{ 'CommonLabel.FirstName' | translate }}"
                 class='form-control' [ngClass]="{ 'is-invalid': !!contactForm.ContactFirstName?.errors }" />
          <img src='../../../../assets/image/user-icon.png' class='user-icon' alt='icon' />
          <div *ngIf='contactForm.ContactFirstName?.errors?.required' class='invalid-feedback'>
            {{ 'Message.FirstNameRequired' | translate }}
          </div>
        </div>
      </div>
      <div class='col-md-12 form-group'>
        <label>{{ 'CommonLabel.LastName' | translate }}</label>
        <div class='wrp'>
          <input formControlName='ContactLastName' placeholder="{{ 'CommonLabel.LastName' | translate }}"
                 class='form-control' [ngClass]="{ 'is-invalid': !!contactForm.ContactLastName?.errors }" />
          <img src='../../../../assets/image/user-icon.png' class='user-icon' alt='icon' />
          <div *ngIf='contactForm.ContactLastName?.errors?.required' class='invalid-feedback'>
            {{ 'Message.LastNameRequired' | translate }}
          </div>
        </div>
      </div>
      <div class='col-md-12 form-group'>
        <label>{{ 'CommonLabel.Email' | translate }}</label>
        <div class='wrp'>
          <input type='text' formControlName='ContactEmail' placeholder="{{ 'CommonLabel.Email' | translate }}"
                 class='form-control' [ngClass]="{ 'is-invalid': !!contactForm.ContactEmail?.errors && contactForm.ContactEmail?.touched }" />
          <img src='../../../../assets/image/email-icon.png' class='user-icon' alt='icon' />
          <div *ngIf='contactForm.ContactEmail?.errors?.required' class='invalid-feedback'>
            {{ 'Message.EmailRequired' | translate }}
          </div>
          <div *ngIf='contactForm.ContactEmail?.errors?.invalidEmail && contactForm.ContactEmail?.touched' class='invalid-feedback'>
            Please enter a valid email address (e.g., name@example.com)
          </div>
        </div>
      </div>
      <div class='col-md-12 form-group'>
        <label>
          {{ 'CommonLabel.PhoneNumber' | translate }}
        </label>
        <div class='wrp'>
          <input type='text'
                 formControlName='ContactPhoneNumber'
                 appPhoneNumber
                 mask='000-000-0000'
                 placeholder="{{ 'CommonLabel.PhoneNumber' | translate }}"
                 minlength='10'
                 class='form-control'
                 maxlength='12'
                 onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode >= 32 && event.charCode <= 47 || event.charCode >= 58 && event.charCode <= 64'
                 [ngClass]="{ 'is-invalid': !!contactForm.ContactPhoneNumber?.errors }" />
          <img src='../../../../assets/image/phone-icon.png' class='user-icon' alt='icon' />
          <div *ngIf='contactForm.ContactPhoneNumber?.errors?.required' class='invalid-feedback'>
            {{ 'Message.ContactPhoneRequired' | translate }}
          </div>
          <div
            *ngIf='contactForm.ContactPhoneNumber?.errors?.minlength'
            class='invalid-feedback'>
            {{ 'Message.ContactPhoneTenDigits' | translate }}
          </div>
          <div *ngIf='contactForm.ContactPhoneNumber?.errors?.pattern' class='invalid-feedback'>
            {{"Message.OnlyNumbersAndSymbols" | translate}}
          </div>
        </div>
      </div>
      <div class='col-md-12 form-group'>
        <label>
          {{ 'CommonLabel.AdditionalEmails' | translate }}
        </label>
        <div class='mat-chips-wrapper'>
          <mat-chip-list #chipList class='chip-list' aria-label='Mail selection'>
            <mat-chip *ngFor='let chip of chips; let i = index' [disabled]='!!WorkId'>
              {{chip}}
              <button matChipRemove (click)='removeChip(chip)' [disabled]='!!WorkId'>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input placeholder='Add Emails'
                   id='mat-input'
                   class='form-control'
                   style='box-shadow: none; padding-left: 40px'
                   formArrayName='AdditionalContactEmail'
                   [matChipInputFor]='chipList'
                   [disabled]='!!WorkId'
                   (matChipInputTokenEnd)='add($event)' />
          </mat-chip-list>
          <img *ngIf='chips.length === 0' src='../../../../assets/image/email-icon.png' style='top: 41px; left: 24px;'
               class='user-icon' />
        </div>
        <div *ngIf='contactForm.AdditionalContactEmail.errors?.invalidEmail' class='error-text'>
          Please enter a valid email address (e.g., name@example.com)
        </div>
      </div>
      <div class='col-md-12 form-group' *ngIf="workOrderType === 'Resident' && !isForResident">
        <label>
          {{ 'CommonLabel.HomeAddress' | translate }}
        </label>
        <div class='wrp'>
          <app-auto-complete
            [address]='contactAddress'
            [saveInfo]='contactInfoSubmit'
            (onAddressChange)='onAddressChange($event)'
          >
          </app-auto-complete>
          <img src='../../../../assets/image/region-icon.png' class='user-icon' alt='icon' />
        </div>
      </div>
    </div>
  </div>
  <div class='foot-btn text-center mt-1 mb-4'>
    <button type='submit' class='btn btn-oclm w-50' (click)='saveContactInfo()'>
      {{ 'BtnText.Save' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #templateConfirm>
  <div class='modal-body text-center'>
    <p>
      This will update the Address and Map location as per the newly selected
      job. Are you sure you would like to change the Job Name?
    </p>
    <div class='modal-footer'>
      <button type='button' class='btn btn-oclm' (click)='cancelLocationChange()'>
        No
      </button>
      <button type='button' class='btn btn-oclm' (click)='confirmChangeLocation()'>
        Yes
      </button>
    </div>
  </div>
</ng-template>

<ng-template #template>
  <div class='modal-body text-center'>
    <p>{{ message }}</p>
    <div class='modal-footer'>
      <button type='button' class='btn btn-oclm' (click)='confirm()'>Ok</button>
    </div>
  </div>
</ng-template>

<ng-template #commentBeforeTemplate>
  <div class='modal-body text-center'>
    <div class='form-group'>
      <label for='comment'>Comment</label>
      <input type='text' [(ngModel)]='uploadBeforeComment' class='form-control' id='comment'
             placeholder='Enter comment' />
    </div>

    <div class='modal-footer'>
      <button type='button' class='btn btn-oclm' (click)='saveBeforeComment(uploadBeforeComment, uploadBeforeId)'>
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #commentAfterTemplate>
  <div class='modal-body text-center'>
    <div class='form-group'>
      <label for='comment'>After Picture</label>
      <input type='text' [(ngModel)]='uploadAfterComment' class='form-control' id='comment'
             placeholder='Enter comment' />
    </div>

    <div class='modal-footer'>
      <button type='button' class='btn btn-oclm' (click)='saveAfterComment(uploadAfterComment, uploadAfterId)'>
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #confirmCreate>
  <div class='modal-body text-center'>
    <p>{{ 'CommonLabel.wouldYouLike' | translate }}</p>
    <div class='modal-footer'>
      <button type='button' class='btn btn-oclm-null' (click)='confirmMySelf()'>
        {{ 'CommonLabel.mySelf' | translate }}
      </button>
      <button
        type='button'
        class='btn btn-oclm'
        data-toggle='modal'
        (click)='createWorkOrderClient()'>
        {{ 'CommonLabel.Client' | translate }}
      </button>
    </div>
  </div>
</ng-template>
